import { user } from "@prisma/client";
import { InputCode } from "@r8er/components/input/code";
import { InputPassword } from "@r8er/components/input/password";
import { InputShortText } from "@r8er/components/input/short-text";
import { ButtonContainer } from "@r8er/components/marketing/button";
import buttonStyles from "@r8er/components/marketing/button/button.module.css";
import { Column, Columns } from "@r8er/components/marketing/columns";
import columnClasses from "@r8er/components/marketing/columns/columns.module.css";
import { IconLogoMarkLight } from "@r8er/components/marketing/icons";
import { Layout as MarketingLayout } from "@r8er/components/marketing/layout";
import layoutClasses from "@r8er/components/marketing/layout/layout.module.css";
import { Newsletter } from "@r8er/components/marketing/newsletter";
import { EMAIL_ADDRESSES } from "@r8er/lib/config/email-addresses";
import { sendEmail } from "@r8er/lib/mail";
import { generateOTP, validateOTP, validateTOTP } from "@r8er/lib/mfa";
import { withMiddleware } from "@r8er/lib/middleware";
import { notify, notifyAdmin } from "@r8er/lib/notifications";
import { authoriseUser } from "@r8er/lib/procedures/authorise_user";
import { createSession, decodeSignInToken } from "@r8er/lib/session";
import styles from "@r8er/styles/Auth.module.css";
import { compare, hash } from "bcrypt";
import { InferGetServerSidePropsType } from "next";
import Head from "next/head";
import Link from "next/link";
import { Secret } from "otpauth";
import { createRef, FormEventHandler, useEffect, useState } from "react";
import parse from "urlencoded-body-parser";

interface SignInFormData {
  email?: string;
  password?: string;
  ["password-confirm"]?: string;
  otp?: string;
  verify_mfa?: string;
}

export const getServerSideProps = withMiddleware(
  // TODO: Fix types
  // @ts-ignore
  async function (context) {
    const {
      req,
      prisma,
      log,
      query: { token, authoriseToken },
    } = context;

    if (typeof token === "string") {
      let userId;
      try {
        ({ userId } = decodeSignInToken(token));
      } catch (error) {
        // TODO: Handle invalid tokens (expired, or wrong)
        console.error(error);
      }

      if (userId) {
        const sessionId = await createSession(prisma, context, userId);
        if (sessionId)
          return {
            redirect: {
              destination: "/app",
              statusCode: 303,
            },
          };
      }
    }

    var investorAuthorisedUser: user | undefined = null;

    if (typeof authoriseToken === "string") {
      try {
        const { email } = authoriseUser.parseReferralToken(authoriseToken);
        investorAuthorisedUser = await prisma.user.findFirst({
          where: {
            email,
          },
        });
      } catch (error) {}
    }

    const setupInvestorAuthorisedUser =
      investorAuthorisedUser && !investorAuthorisedUser?.password
        ? true
        : false;

    let email: string | null = setupInvestorAuthorisedUser
      ? investorAuthorisedUser.email
      : null;
    let password: string | null = null;
    let passwordConfirm: string | null = null;
    let otp: string | null = null;
    let verify_mfa: string | null = null;
    if ("POST" === req.method) {
      ({
        email,
        password,
        otp,
        verify_mfa,
        "password-confirm": passwordConfirm,
      } = (await parse(req)) as SignInFormData);

      if (email) {
        // TODO: handle missing user
        const user = await prisma.user.findFirst({
          where: {
            email: {
              equals: email,
              mode: "insensitive",
            },
          },
          include: {
            company_profile: true,
            assessor_profile: true,
            investor_profile: true,
          },
        });

        if (!user) {
          return {
            props: {
              email,
              missingEmail: true,
            },
          };
        }

        if (user.incorrect_login_attempts >= 5)
          return {
            props: {
              email,
              accountLocked: true,
            },
          };

        if (user.deactivated)
          return {
            props: {
              email,
              deactivated: true,
            },
          };

        if (
          !setupInvestorAuthorisedUser &&
          verify_mfa !== "yes" &&
          !(await compare(password, user.password ?? ""))
        ) {
          const incorrect_login_attempts = user.incorrect_login_attempts + 1;

          await prisma.user.update({
            where: {
              id: user.id,
            },
            data: {
              incorrect_login_attempts,
            },
          });

          if (incorrect_login_attempts >= 5) {
            const userType = user.assessor_profile
              ? "Assessor"
              : user.investor_profile
              ? "Investor"
              : "Company";

            await notifyAdmin(prisma, {
              type: "account-locked",
              content: `${user.firstname} ${
                user.lastname
              } used the wrong password too many times. \nUnlock them in the admin panel under the ${
                user.investor_profile?.company_name ??
                user.assessor_profile?.company_name ??
                user.company_profile?.name
              } ${userType} account`,
              subject: `${userType} (${user.firstname} ${user.lastname}) account has been locked`,
            });

            await notify(
              prisma,
              [
                {
                  email: EMAIL_ADDRESSES.LOCKED,
                },
              ],
              {
                type: "account-locked",
                content: `${user.firstname} ${
                  user.lastname
                } used the wrong password too many times. \nUnlock them in the admin panel under the ${
                  user.investor_profile?.company_name ??
                  user.assessor_profile?.company_name ??
                  user.company_profile?.name
                } ${userType} account`,
                subject: `${userType} (${user.firstname} ${user.lastname}) account has been locked`,
              }
            );

            console.error(`Account locked for ${user.id}`);

            return {
              props: {
                email,
                incorrectPassword: true,
                accountLocked: true,
              },
            };
          }

          console.error(`Invalid password for ${user.id}`);
          return {
            props: {
              email,
              incorrectPassword: true,
              incorrectAttemptCount: incorrect_login_attempts,
            },
          };
        }

        if (verify_mfa !== "yes") {
          const emailMfa = !user.mfa_secret;
          if (emailMfa) {
            await sendEmail(
              email,
              {
                name: `${user.firstname} ${user.lastname}`,
                code: await generateOTP(prisma, user.id),
              },
              "mfa-code"
            );
          }
          return {
            props: {
              mfaRequired: true,
              emailMfa,
              email,
              setupInvestorAuthorisedUser,
            },
          };
        }

        if (verify_mfa === "yes") {
          const validMfa = user.mfa_secret
            ? validateTOTP(Secret.fromBase32(user.mfa_secret), otp)
            : await validateOTP(prisma, user.id, otp);

          if (!validMfa) {
            console.error({
              message: `Invalid ${user.mfa_secret ? "MFA" : "TOTP"} for ${
                user.id
              }`,
              otp_timestamp: user.otp_timestamp,
              timestamp: new Date(),
            });
            return {
              props: {
                mfaRequired: true,
                email,
                incorrectMfa: true,
                setupInvestorAuthorisedUser,
              },
            };
          }
        }

        if (setupInvestorAuthorisedUser) {
          if (password !== passwordConfirm) {
            const emailMfa = !user.mfa_secret;
            if (emailMfa) {
              await sendEmail(
                email,
                {
                  name: `${user.firstname} ${user.lastname}`,
                  code: await generateOTP(prisma, user.id),
                },
                "mfa-code"
              );
            }

            return {
              props: {
                email,
                missingEmail: null,
                incorrectPassword: false,
                passwordMismatch: true,
                incorrectMfa: false,
                accountLocked: false,
                deactivated: false,
                incorrectAttemptCount: 0,
                setupInvestorAuthorisedUser,
                mfaRequired: true,
                emailMfa: true,
              },
            };
          }
          const hashedPassword = await hash(password, 10);
          await prisma.user.update({
            where: {
              id: user.id,
            },
            data: {
              password: hashedPassword,
            },
          });
        } else {
          await prisma.user.update({
            where: {
              id: user.id,
            },
            data: {
              incorrect_login_attempts: 0,
            },
          });
        }

        const sessionId = await createSession(prisma, context, user.id);

        log
          .with({
            sessionId,
            user_id: user.id,
            company_profile_id: user.company_profile?.id,
            investor_profile_id: user.investor_profile?.id,
            assessor_profile_id: user.assessor_profile?.id,
          })
          .info("Signed in");

        if (sessionId)
          return {
            redirect: {
              destination: "/app",
              statusCode: 303,
            },
          };
      }
    }

    return {
      props: {
        email,
        missingEmail: null,
        incorrectPassword: false,
        passwordMismatch: false,
        incorrectMfa: false,
        accountLocked: false,
        deactivated: false,
        incorrectAttemptCount: 0,
        setupInvestorAuthorisedUser,
      },
    };
  },
  { mode: "unauthenticated" }
);

const Watermark = () => (
  <svg
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      transform: "translate(-75%, -15%)",
      opacity: "50%",
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="649.6"
    height="1051.7"
  >
    <path
      fill="#f0f0f0"
      d="M516 79a270 270 0 00-382 0 270 270 0 00-23 356l49-48c-56-79-49-190 22-260a203 203 0 01286 0c79 79 79 207 0 286L325 556l-74-73-48 47 122 122 191-191a270 270 0 000-382"
    />
    <path
      fill="#f0f0f0"
      d="M555 497l-15-14-48 47 15 15a258 258 0 010 364 258 258 0 01-364 0 258 258 0 010-364l182-182 74 74 48-48-122-122L95 497a325 325 0 000 460 325 325 0 00460 0 325 325 0 000-460"
    />
  </svg>
);

const SignIn = ({
  email,
  missingEmail,
  incorrectPassword,
  accountLocked,
  mfaRequired,
  incorrectMfa,
  deactivated,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formRef = createRef<HTMLFormElement | undefined>();
  const SubmitFormWithPending: FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (isSubmitting) formRef.current?.submit();
  }, [isSubmitting]);

  return (
    <MarketingLayout>
      <Head>
        <title>Login - R8ER</title>
      </Head>

      <Columns
        count={2}
        style={{
          minHeight: "950px",
          gridColumnGap: 0,
          borderBottom: "8px solid var(--brand-secondary)",
        }}
      >
        <Column classList={[columnClasses.marketing__columns_inner_background]}>
          {mfaRequired ? (
            <div className={columnClasses.marketing__columns_inner_center_wide}>
              <Watermark />
              <h2 style={{ color: "var(--brand-secondary)" }}>
                Two factor Security Code
              </h2>
              <label>
                Please enter the security code from your 2FA device, or email.
              </label>
              {incorrectMfa && (
                <span className={layoutClasses.text__red}>
                  <br />
                  Incorrect code
                </span>
              )}
              <form
                onSubmitCapture={SubmitFormWithPending}
                ref={formRef}
                method="post"
                style={{ marginTop: "2.5rem" }}
              >
                <input
                  type="hidden"
                  name="verify_mfa"
                  id="verify_mfa"
                  defaultValue="yes"
                />
                <input
                  type="hidden"
                  name="email"
                  id="email"
                  defaultValue={email ?? ""}
                />

                <InputCode
                  inputProps={{
                    id: "otp",
                    name: "otp",
                  }}
                />
                <br />
                <div>
                  <button
                    className={[
                      buttonStyles.button,
                      buttonStyles.button_primary,
                    ].join(" ")}
                  >
                    Log In
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className={columnClasses.marketing__columns_inner_center_wide}>
              <Watermark />

              <h2 style={{ color: "var(--brand-secondary)" }}>
                Login to your account
              </h2>

              {missingEmail && (
                <span className={layoutClasses.text__red}>Email not found</span>
              )}
              {accountLocked && (
                <span className={layoutClasses.text__red}>
                  Too many incorrect password attempts. Please contact
                  admin@r8er.co.uk to unlock your account.
                </span>
              )}
              {!accountLocked && incorrectPassword && (
                <span className={layoutClasses.text__red}>
                  Incorrect password.
                </span>
              )}
              {deactivated && (
                <span className={layoutClasses.text__red}>
                  User account deactivated. Please contact admin@r8er.co.uk if
                  you believe this is a mistake
                </span>
              )}

              <form
                method="post"
                style={{ marginTop: "2.5rem" }}
                onSubmitCapture={SubmitFormWithPending}
                ref={formRef}
              >
                <InputShortText
                  id="email"
                  name="email"
                  defaultValue={email ?? ""}
                  placeholder="Email"
                  inputMode="email"
                  className={missingEmail ? styles.errorField : ""}
                />
                <InputPassword
                  id="password"
                  name="password"
                  placeholder="Password"
                  defaultValue={""}
                  className={
                    incorrectPassword || accountLocked ? styles.errorField : ""
                  }
                />
                <div style={{ marginBottom: "2rem" }}>
                  <Link href="/auth/reset-password">Forgot your password?</Link>
                </div>
                <div>
                  <button
                    className={[
                      buttonStyles.button,
                      buttonStyles.button_primary,
                    ].join(" ")}
                    disabled={isSubmitting}
                  >
                    Log In
                  </button>
                </div>
              </form>
            </div>
          )}
        </Column>
        <Column
          classList={[columnClasses.marketing__columns_inner_overlay]}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: "url(/signin-cover.png)",
          }}
        >
          <div
            className={columnClasses.marketing__columns_inner_center_wide}
            style={{ maxWidth: "550px" }}
          >
            <h2>Welcome to R8ER</h2>
            <p>
              The world's first, professionally vetted, digital evaluation
              platform for disruptive healthcare that connects technology with
              investment.
            </p>
            <div style={{ marginTop: "4rem" }}>
              <IconLogoMarkLight />
            </div>
          </div>
        </Column>
      </Columns>

      <Newsletter />
    </MarketingLayout>
  );
};

const SetupAccount = ({
  email,
  missingEmail,
  accountLocked,
  mfaRequired,
  incorrectMfa,
  deactivated,
  passwordMismatch,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const [errors, setErrors] = useState<string[]>(
    passwordMismatch ? ["Confirmation password does not match."] : []
  );

  const SubmitForm: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setErrors([]);
    const passwordStrengthElement =
      e?.currentTarget?.elements?.["password-strength"];
    const passwordFeedbackWarningElement =
      e?.currentTarget?.elements?.["password-feedback-warning"];
    const passwordFeedbackSuggestionsElement =
      e?.currentTarget?.elements?.["password-feedback-suggestions"];
    if (passwordStrengthElement) {
      const passwordStrength = parseInt(passwordStrengthElement.value);
      if (passwordStrength < 2) {
        return setErrors((e) => [
          ...e,
          `Password too weak: ${
            passwordFeedbackWarningElement?.value ||
            passwordFeedbackSuggestionsElement?.value ||
            "Password too short. Please enter at least 4 characters"
          }`,
        ]);
      }
    }

    // @ts-ignore
    e.target.submit();
  };

  return (
    <MarketingLayout>
      <Head>
        <title>Login - R8ER</title>
      </Head>
      <Columns
        count={2}
        style={{
          minHeight: "950px",
          gridColumnGap: 0,
          borderBottom: "8px solid var(--brand-secondary)",
        }}
      >
        <Column classList={[columnClasses.marketing__columns_inner_background]}>
          {mfaRequired ? (
            <div className={columnClasses.marketing__columns_inner_center_wide}>
              <Watermark />
              <h2 style={{ color: "var(--brand-secondary)" }}>
                Two factor Security Code
              </h2>
              <label>
                Please enter the security code from your email, and setup a new
                password.
              </label>
              {incorrectMfa && (
                <span className={layoutClasses.text__red}>
                  <br />
                  Incorrect code
                </span>
              )}
              <form
                method="post"
                style={{ marginTop: "2.5rem" }}
                onSubmitCapture={SubmitForm}
              >
                <input
                  type="hidden"
                  name="verify_mfa"
                  id="verify_mfa"
                  defaultValue="yes"
                />
                <input
                  type="hidden"
                  name="email"
                  id="email"
                  defaultValue={email ?? ""}
                />

                <InputCode
                  inputProps={{
                    id: "otp",
                    name: "otp",
                  }}
                />

                <br />
                <InputPassword
                  id="password"
                  name="password"
                  placeholder="Password"
                  defaultValue={""}
                  showStrength
                  className={accountLocked ? styles.errorField : ""}
                />
                <InputPassword
                  id="password-confirm"
                  name="password-confirm"
                  placeholder="Confirm password"
                />
                <br />
                <ButtonContainer style={{ marginBottom: "10rem" }}>
                  {errors.length > 0 && (
                    <div className={layoutClasses.text__red}>
                      {errors.map((e) => (
                        <div>{e}</div>
                      ))}
                    </div>
                  )}
                  <button
                    className={[
                      buttonStyles.button,
                      buttonStyles.button_primary,
                    ].join(" ")}
                  >
                    Setup
                  </button>
                </ButtonContainer>
              </form>
            </div>
          ) : (
            <div className={columnClasses.marketing__columns_inner_center_wide}>
              <Watermark />

              <h2 style={{ color: "var(--brand-secondary)" }}>
                Setup your account
              </h2>

              {missingEmail && (
                <span className={layoutClasses.text__red}>Email not found</span>
              )}
              {accountLocked && (
                <span className={layoutClasses.text__red}>
                  Too many incorrect password attempts. Please contact
                  admin@r8er.co.uk to unlock your account.
                </span>
              )}
              {deactivated && (
                <span className={layoutClasses.text__red}>
                  User account deactivated. Please contact admin@r8er.co.uk if
                  you believe this is a mistake
                </span>
              )}

              <form method="post" style={{ marginTop: "2.5rem" }}>
                <InputShortText
                  id="email"
                  name="email"
                  defaultValue={email ?? ""}
                  placeholder="Email"
                  inputMode="email"
                  className={missingEmail ? styles.errorField : ""}
                />
                <div>
                  <button
                    className={[
                      buttonStyles.button,
                      buttonStyles.button_primary,
                    ].join(" ")}
                  >
                    Log In
                  </button>
                </div>
              </form>
            </div>
          )}
        </Column>
        <Column
          classList={[columnClasses.marketing__columns_inner_overlay]}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: "url(/signin-cover.png)",
          }}
        >
          <div
            className={columnClasses.marketing__columns_inner_center_wide}
            style={{ maxWidth: "550px" }}
          >
            <h2>Welcome to R8ER</h2>
            <p>
              The world's first, professionally vetted, digital evaluation
              platform for disruptive healthcare that connects technology with
              investment.
            </p>
            <div style={{ marginTop: "4rem" }}>
              <IconLogoMarkLight />
            </div>
          </div>
        </Column>
      </Columns>

      <Newsletter />
    </MarketingLayout>
  );
};

export default function Page({
  setupInvestorAuthorisedUser,
  ...props
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return setupInvestorAuthorisedUser ? (
    <SetupAccount {...props} />
  ) : (
    <SignIn {...props} />
  );
}
